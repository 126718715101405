@import "../../include";

.partBenefits {
  @extend %container;

  &__blockTitle {
    margin-bottom: 40px;

    font-family: Shoulders, sans-serif;
    font-size: 52px;
    font-weight: 900;
    line-height: 1.1;
    text-transform: uppercase;

    i {
      font-style: normal;
      color: #ff6d3f;
    }

    @media #{$phone} {
      font-size: 46px;
    }
  }
}

.benefitsList {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  &__item {
    display: flex;
    flex-direction: column;

    width: 48.33%;
    margin-bottom: 20px;
    padding-right: 5px;
    padding-left: 5px;

    @media #{$phone} {
      flex-direction: row;

      width: 100%;
      margin-bottom: 24px;
      padding-right: 0;
      padding-left: 0;
    }

    @media screen and (width <= 550px) {
      flex-direction: column;
    }
  }

  &__itemImage {
    display: flex;
    height: 112px;
    margin-bottom: 20px;

    @media #{$phone} {
      flex: 0 0 25%;
    }

    @media screen and (width <= 550px) {
      flex: unset;
      justify-content: center;
    }
  }

  &__itemTitle {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.1;
  }

  &__itemText {
    font-size: 16px;
    line-height: 1.6;
  }

  @media #{$phone} {
    margin-right: 0;
    margin-left: 0;
  }
}
