@import "../../include";

.banner {
  position: relative;
  padding: 130px 0 220px;

  @media #{$phone} {
    padding: 42px 0 82px;
  }
}

.bannerImage {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;

  &_desktop {
    @media #{$phone} {
      display: none;
    }
  }

  &_mobileMD {
    @media #{$tablet-and-desktop} {
      display: none;
    }

    @media #{$phone} {
      display: block;
    }

    @media (width <= 550px) {
      display: none;
    }
  }

  &_mobileSM {
    @media #{$tablet-and-desktop} {
      display: none;
    }

    @media #{$phone} {
      display: none;
    }

    @media (width <= 550px) {
      display: block;
    }
  }
}

.bannerContent {
  @extend %container;

  & > * {
    @media #{$phone} {
      max-width: 60%;
    }
  }

  h1 {
    font-family: Shoulders, sans-serif;
    font-size: 66px;
    font-weight: 900;
    line-height: 1.1;

    i {
      font-style: normal;
      color: #ff6d3f;
    }

    @media #{$phone} {
      font-size: 31px;
    }
  }

  ul {
    margin: 5% 2% 0 7%;
    list-style-type: circle;

    li {
      font-size: 30px;
      font-weight: 300;
      line-height: 1.6;

      @media #{$phone} {
        font-size: 19px;
      }
    }
  }
}
